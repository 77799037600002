import { json as jsonLang } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';
import { useState } from 'react';
import { Loader, Plus, X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import EmirateTable from '../components/emirates/EmiratesTable';
import Layout from '../components/layout';
import Modal from '../components/shared/Modal';
import useAuth from '../hooks/useAuth';
import CreateEmirateRequest from '../models/emirate/CreateEmirateRequest';
import emirateService from '../services/EmirateService';

export default function Emirates() {
  const [name, setName] = useState('');
  const [geometryStr, setGeometryStr] = useState('[]');

  const [addEmirateShow, setAddEmirateShow] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { authenticatedUser }: any = useAuth();
  const { data, isLoading } = useQuery(
    ['emirates', name],
    () =>
      emirateService.findAll({
        name: name || undefined,
      }),
    {
      refetchInterval: 1000,
    },
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    reset,
  } = useForm<CreateEmirateRequest>();

  const saveEmirate = async (createEmirateRequest: CreateEmirateRequest) => {
    try {
      await emirateService.save(createEmirateRequest);
      setAddEmirateShow(false);
      reset();
      setError(null);
    } catch (error: any) {
      setError(error.response.data.message);
    }
  };

  return (
    <Layout>
      <h1 className="font-semibold text-3xl mb-5">Manage Emirate data</h1>
      <hr />
      {authenticatedUser.role === 'admin' ? (
        <button
          className="btn my-5 flex gap-2 w-full sm:w-auto justify-center"
          onClick={() => setAddEmirateShow(true)}
        >
          <Plus /> Add Emirate
        </button>
      ) : null}

      <div className="table-filter">
        <div className="flex flex-row gap-5">
          <input
            type="text"
            className="input"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>

      <EmirateTable data={data} isLoading={isLoading} />

      {/* Add Emirate Modal */}
      <Modal show={addEmirateShow}>
        <div className="flex">
          <h1 className="font-semibold mb-3">Add Emirate</h1>
          <button
            className="ml-auto focus:outline-none"
            onClick={() => {
              reset();
              setAddEmirateShow(false);
            }}
          >
            <X size={30} />
          </button>
        </div>
        <hr />

        <form
          className="flex flex-col gap-5 mt-5"
          onSubmit={handleSubmit(saveEmirate)}
        >
          <input
            type="text"
            className="input"
            placeholder="Name"
            disabled={isSubmitting}
            required
            {...register('name')}
          />
          <input
            type="text"
            className="input"
            placeholder="Color"
            disabled={isSubmitting}
            required
            {...register('color')}
          />

          <div className="max-h-[180px]">
            <CodeMirror
              value={geometryStr}
              height="100%"
              maxHeight="200px"
              style={{ height: '100%' }}
              extensions={[jsonLang()]}
              onChange={(value) => {
                setGeometryStr(value);
                try {
                  setValue('geometry', JSON.parse(value));
                } catch (e) {}
              }}
            />
          </div>
          <button className="btn" disabled={isSubmitting}>
            {isSubmitting ? (
              <Loader className="animate-spin mx-auto" />
            ) : (
              'Save'
            )}
          </button>
          {error ? (
            <div className="text-red-500 p-3 font-semibold border rounded-md bg-red-50">
              {error}
            </div>
          ) : null}
        </form>
      </Modal>
    </Layout>
  );
}
