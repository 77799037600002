export default function MapSideBarDocuments({ project }: any) {
  return (
    <div className="w-full h-auto text-sm bg-gray-200">
      {project.documents.map((document: any) => (
        <p
          className="w-full font-bold text-md border-b border-gray-300 p-2"
          key={`document-${document.id}`}
        >
          <a
            className="no-underline cursor-pointer"
            href={document.url}
            target="_blank"
          >
            {document.originalName}
          </a>
        </p>
      ))}
    </div>
  );
}
