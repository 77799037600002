import GoogleMapReact from 'google-map-react';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import Filters from '../components/filters';
import MapSideBar from '../components/map-sidebar';
import Marker from '../components/marker';
import useAuth from '../hooks/useAuth';
import Project from '../models/project/Project';
import authService from '../services/AuthService';
import EmirateService from '../services/EmirateService';
import ProjectService from '../services/ProjectService';

export default function PublicMap() {
  const history = useHistory();

  const { setAuthenticatedUser }: any = useAuth();
  const [filters, setFilters] = useState<{
    open: boolean;
    districts: number[];
  }>({
    open: false,
    districts: [],
  });
  const mapRef = useRef(null);
  const { data } = useQuery(['emirates'], () => EmirateService.findAll({}));
  const [projectId, setProjectId] = useState<any>(null);
  const { data: projects } = useQuery(['projects'], () =>
    ProjectService.findAll({}),
  );
  useEffect(() => {
    const map = mapRef.current;
    if (window.google) {
      data?.forEach(({ color, geometry }: any) => {
        const polygon = new window.google.maps.Polygon({
          paths: geometry,
          strokeColor: '#CCC',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: 0.35,
        });
        polygon.setMap(map);
      });
    }
  }, [data]);

  const handleLogout = async () => {
    await authService.logout();
    setAuthenticatedUser(null as any);
    history.push('/login');
  };
  return (
    <div>
      <hr />
      <div
        style={{
          height: '100vh',
          width: '100%',
          position: 'relative',
        }}
      >
        <div
          className="absolute right-1 grid grid-cols-12 px-10"
          style={{
            zIndex: 1,
            height: 50,
            left: '30%',
            top: 6,
            border: '1px solid #ccc',
            borderRadius: 10,
          }}
        >
          <div className="col-span-10 flex items-center justify-start gap-4">
            <button
              className="bg-white p-2 px-4 rounded-lg cursor-pointer font-bold"
              onClick={() => {
                setFilters((filter) => ({
                  ...filter,
                  open: true,
                }));
              }}
            >
              Eamitares
            </button>
            <button
              className="bg-white p-2 px-4 rounded-lg cursor-pointer font-bold"
              onClick={() => {
                setFilters({ open: false, districts: [] });
              }}
            >
              Clear Filters
            </button>
          </div>
          <div className="col-span-2">
            <button
              className="bg-white p-2 px-4 rounded-lg cursor-pointer font-bold self-end"
              onClick={handleLogout}
            >
              Log Out
            </button>
          </div>
        </div>
        <Filters
          isOpen={filters.open}
          setIsOpen={(open) => {
            setFilters((filter) => ({
              ...filter,
              open,
            }));
          }}
          filter={(districts: number[]) =>
            setFilters({
              open: false,
              districts,
            })
          }
          emirates={data}
        />
        {projectId && (
          <MapSideBar close={() => setProjectId(null)} id={projectId} />
        )}
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyBF5hbbd6d0p3meAT_ZEAS-ic0-d4PW3ZU',
          }}
          defaultCenter={{ lat: 24.453327, lng: 54.252636 }}
          defaultZoom={7.5}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }: any) => {
            mapRef.current = map;
            const bounds = new window.google.maps.LatLngBounds();
            map.fitBounds(bounds);
            map.setOptions({
              mapTypeControl: true,
              fullscreenControl: false,
              panControl: true,
              draggable: true,
              zoomControl: true,
              streetViewControl: true,
              locationControl: true,
              center: { lat: 24.453327, lng: 54.252636 },
            });
          }}
        >
          {(projects || []).map(
            (marker: Project) =>
              (filters.districts.length == 0 ||
                filters.districts.includes(marker.district?.id)) && (
                <Marker
                  key={marker.id}
                  lat={marker.point.lat}
                  lng={marker.point.lng}
                  name={marker.name}
                  description={marker.description}
                  onClick={() => setProjectId(marker.id)}
                />
              ),
          )}
        </GoogleMapReact>
      </div>
      <hr />
    </div>
  );
}
