import { createContext, Dispatch, SetStateAction, useState } from 'react';

import User from '../models/user/User';

interface AuthContextValue {
  authenticatedUser: User;
  setAuthenticatedUser: Dispatch<SetStateAction<User>>;
}

export const AuthenticationContext = createContext<AuthContextValue | null>(
  null,
);

export function AuthenticationProvider({ children }: any) {
  const [authenticatedUser, setAuthenticatedUser] = useState<User>();

  return (
    <AuthenticationContext.Provider
      value={{ authenticatedUser, setAuthenticatedUser } as any}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
