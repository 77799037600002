import { useState } from 'react';
import { AlertTriangle, Loader, X } from 'react-feather';
import { useForm } from 'react-hook-form';

import useAuth from '../../hooks/useAuth';
import Company from '../../models/company/Company';
import UpdateCompanyRequest from '../../models/company/UpdateCompanyRequest';
import companyService from '../../services/CompanyService';
import Modal from '../shared/Modal';
import Table from '../shared/Table';
import TableItem from '../shared/TableItem';

interface CompanyTableProps {
  data?: Company[];
  isLoading: boolean;
}

export default function CompanyTable({
  data = [],
  isLoading,
}: CompanyTableProps) {
  const { authenticatedUser }: any = useAuth();
  const [deleteShow, setDeleteShow] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  const [error, setError] = useState<string | null>();
  const [updateShow, setUpdateShow] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setValue,
  } = useForm<UpdateCompanyRequest>();

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await companyService.delete(selectedCompanyId);
      setDeleteShow(false);
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleUpdate = async (updateCompanyRequest: UpdateCompanyRequest) => {
    try {
      await companyService.update(selectedCompanyId, updateCompanyRequest);
      setUpdateShow(false);
      reset();
      setError(null);
    } catch (error: any) {
      setError(error.response.data.message);
    }
  };

  return (
    <>
      <div className="table-container">
        <Table
          columns={['ID', 'Name', 'Contact', 'Phone', 'Type', 'P/C', 'Active']}
        >
          {isLoading
            ? null
            : data.map(
                ({
                  id,
                  name,
                  contactName,
                  contactPhone,
                  type,
                  projectsCount,
                  isActive,
                }) => (
                  <tr key={id}>
                    <TableItem>{id}</TableItem>
                    <TableItem>{name}</TableItem>
                    <TableItem>{contactName}</TableItem>
                    <TableItem>{contactPhone}</TableItem>
                    <TableItem>{type}</TableItem>
                    <TableItem>{projectsCount}</TableItem>
                    <TableItem>{isActive}</TableItem>
                    <TableItem className="text-right">
                      {['admin'].includes(authenticatedUser.role) ? (
                        <>
                          <button
                            className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                            onClick={() => {
                              setSelectedCompanyId(id);

                              setValue('name', name);
                              setValue('contactName', contactName);
                              setValue('contactPhone', contactPhone);
                              setValue('type', type);
                              setValue('isActive', isActive);

                              setUpdateShow(true);
                            }}
                          >
                            Edit
                          </button>

                          <button
                            className="text-red-600 hover:text-red-900 ml-3 focus:outline-none"
                            disabled
                            onClick={() => {
                              setSelectedCompanyId(id);
                              setDeleteShow(true);
                            }}
                          >
                            Delete
                          </button>
                        </>
                      ) : null}
                    </TableItem>
                  </tr>
                ),
              )}
        </Table>
        {!isLoading && data.length < 1 ? (
          <div className="text-center my-5 text-gray-500">
            <h1>Empty</h1>
          </div>
        ) : null}
      </div>
      {/* Delete Company Modal */}
      <Modal show={deleteShow}>
        <AlertTriangle size={30} className="text-red-500 mr-5 fixed" />
        <div className="ml-10">
          <h3 className="mb-2 font-semibold">Delete Company</h3>
          <hr />
          <p className="mt-2">
            Are you sure you want to delete the Company? All of Company's data
            will be permanently removed.
            <br />
            This action cannot be undone.
          </p>
        </div>
        <div className="flex flex-row gap-3 justify-end mt-5">
          <button
            className="btn"
            onClick={() => {
              setError(null);
              setDeleteShow(false);
            }}
            disabled={isDeleting}
          >
            Cancel
          </button>
          <button
            className="btn danger"
            onClick={handleDelete}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <Loader className="mx-auto animate-spin" />
            ) : (
              'Delete'
            )}
          </button>
        </div>
        {error ? (
          <div className="text-red-500 p-3 font-semibold border rounded-md bg-red-50">
            {error}
          </div>
        ) : null}
      </Modal>
      {/* Update Company Modal */}
      <Modal show={updateShow}>
        <div className="flex">
          <h1 className="font-semibold mb-3">Update Company</h1>
          <button
            className="ml-auto focus:outline-none"
            onClick={() => {
              setUpdateShow(false);
              setError(null);
              reset();
            }}
          >
            <X size={30} />
          </button>
        </div>
        <hr />

        <form
          className="flex flex-col gap-5 mt-5"
          onSubmit={handleSubmit(handleUpdate)}
        >
          <input
            type="text"
            className="input"
            placeholder="Name"
            required
            {...register('name')}
          />
          <input
            type="text"
            className="input"
            placeholder="Contact Name"
            required
            {...register('contactName')}
          />
          <input
            type="text"
            className="input"
            placeholder="Contact Phone"
            required
            {...register('contactPhone')}
          />
          <input
            type="text"
            className="input"
            placeholder="Type"
            required
            {...register('type')}
          />
          <input
            type="checkbox"
            className="input"
            placeholder="Active"
            required
            {...register('isActive')}
          />
          <button className="btn" disabled={isSubmitting}>
            {isSubmitting ? (
              <Loader className="animate-spin mx-auto" />
            ) : (
              'Save'
            )}
          </button>
          {error ? (
            <div className="text-red-500 p-3 font-semibold border rounded-md bg-red-50">
              {error}
            </div>
          ) : null}
        </form>
      </Modal>
    </>
  );
}
