import Accordion from './accordion';

export default function MapSideBarPaymentPlans({ project }: any) {
  return (
    <div className="w-full h-auto text-sm">
      <Accordion
        items={project.paymentPlans.map((plan: any) => ({
          id: plan.id,
          title: plan.name,
          subTitle: plan.description,
          content: plan.items.map((item: any) => (
            <div
              key={`plan-item-${item.id}`}
              className="w-full flex justify-between items-center"
            >
              <span>{item.name}</span>
              <span>{item.description}</span>
            </div>
          )),
        }))}
      />
    </div>
  );
}
