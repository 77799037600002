import { useState } from 'react';
import { Loader, Plus, X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import CompanyTable from '../components/companies/CompaniesTable';
import Layout from '../components/layout';
import Modal from '../components/shared/Modal';
import useAuth from '../hooks/useAuth';
import CreateCompanyRequest from '../models/company/CreateCompanyRequest';
import companyService from '../services/CompanyService';

export default function Companies() {
  const [name, setName] = useState('');

  const [addEmirateShow, setAddEmirateShow] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { authenticatedUser }: any = useAuth();
  const { data, isLoading } = useQuery(
    ['companies', name],
    () =>
      companyService.findAll({
        name: name || undefined,
      }),
    {
      refetchInterval: 1000,
    },
  );

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<CreateCompanyRequest>();

  const saveCompany = async (createCompanyRequest: CreateCompanyRequest) => {
    try {
      await companyService.save(createCompanyRequest);
      setAddEmirateShow(false);
      reset();
      setError(null);
    } catch (error: any) {
      setError(error.response.data.message);
    }
  };

  return (
    <Layout>
      <h1 className="font-semibold text-3xl mb-5">Manage Companies</h1>
      <hr />
      {authenticatedUser.role === 'admin' ? (
        <button
          className="btn my-5 flex gap-2 w-full sm:w-auto justify-center"
          onClick={() => setAddEmirateShow(true)}
        >
          <Plus /> Add Company
        </button>
      ) : null}

      <div className="table-filter">
        <div className="flex flex-row gap-5">
          <input
            type="text"
            className="input"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>

      <CompanyTable data={data} isLoading={isLoading} />
      {/* Add Emirate Modal */}
      <Modal show={addEmirateShow}>
        <div className="flex">
          <h1 className="font-semibold mb-3">Add Emirate</h1>
          <button
            className="ml-auto focus:outline-none"
            onClick={() => {
              reset();
              setAddEmirateShow(false);
            }}
          >
            <X size={30} />
          </button>
        </div>
        <hr />

        <form
          className="flex flex-col gap-5 mt-5"
          onSubmit={handleSubmit(saveCompany)}
        >
          <input
            type="text"
            className="input"
            placeholder="Name"
            required
            {...register('name')}
          />
          <input
            type="text"
            className="input"
            placeholder="Contact Name"
            required
            {...register('contactName')}
          />
          <input
            type="text"
            className="input"
            placeholder="Contact Phone"
            required
            {...register('contactPhone')}
          />
          <input
            type="text"
            className="input"
            placeholder="Type"
            required
            {...register('type')}
          />
          <input
            type="checkbox"
            className="input"
            placeholder="Active"
            required
            {...register('isActive')}
          />

          <button className="btn" disabled={isSubmitting}>
            {isSubmitting ? (
              <Loader className="animate-spin mx-auto" />
            ) : (
              'Save'
            )}
          </button>
          {error ? (
            <div className="text-red-500 p-3 font-semibold border rounded-md bg-red-50">
              {error}
            </div>
          ) : null}
        </form>
      </Modal>
    </Layout>
  );
}
