import { json as jsonLang } from '@codemirror/lang-json';
import CodeMirror from '@uiw/react-codemirror';
import { useCallback, useState } from 'react';
import { AlertTriangle, Loader, X } from 'react-feather';
import { useForm } from 'react-hook-form';

import useAuth from '../../hooks/useAuth';
import Emirate from '../../models/emirate/Emirate';
import UpdateEmirateRequest from '../../models/emirate/UpdateEmirateRequest';
import emirateService from '../../services/EmirateService';
import Modal from '../shared/Modal';
import Table from '../shared/Table';
import TableItem from '../shared/TableItem';

interface EmirateTableProps {
  data?: Emirate[];
  isLoading: boolean;
}

export default function EmirateTable({
  data = [],
  isLoading,
}: EmirateTableProps) {
  const { authenticatedUser }: any = useAuth();
  const [deleteShow, setDeleteShow] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedEmirateId, setSelectedEmirateId] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [updateShow, setUpdateShow] = useState<boolean>(false);
  const [geometryStr, setGeometryStr] = useState<any>('[]');

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setValue,
  } = useForm<UpdateEmirateRequest>();

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await emirateService.delete(selectedEmirateId);
      setDeleteShow(false);
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleUpdate = async (updateEmirateRequest: UpdateEmirateRequest) => {
    try {
      await emirateService.update(selectedEmirateId, updateEmirateRequest);
      setUpdateShow(false);
      reset();
      setError(null);
    } catch (error: any) {
      setError(error.response.data.message);
    }
  };
  const formatJson = useCallback((code, replacer = 2) => {
    try {
      if (code) {
        const obj = JSON.parse(code);
        const str = JSON.stringify(obj, null, replacer);
        return str;
      }
    } catch (error) {
      if (error instanceof Error) {
      } else {
        throw error;
      }
    }
  }, []);

  return (
    <>
      <div className="table-container">
        <Table columns={['ID', 'Name', 'Color']}>
          {isLoading
            ? null
            : data.map(({ id, name, color, geometry }) => (
                <tr key={id}>
                  <TableItem>{id}</TableItem>
                  <TableItem>{name}</TableItem>
                  <TableItem className={`text-[${color}]`}>{color}</TableItem>
                  <TableItem className="text-right">
                    {['admin'].includes(authenticatedUser.role) ? (
                      <>
                        <button
                          className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                          onClick={() => {
                            setSelectedEmirateId(id);

                            setValue('name', name);
                            setValue('color', color);
                            setValue('geometry', geometry);
                            setGeometryStr(
                              formatJson(JSON.stringify(geometry)),
                            );

                            setUpdateShow(true);
                          }}
                        >
                          Edit
                        </button>

                        <button
                          className="text-red-600 hover:text-red-900 ml-3 focus:outline-none"
                          disabled
                          onClick={() => {
                            setSelectedEmirateId(id);
                            setDeleteShow(true);
                          }}
                        >
                          Delete
                        </button>
                      </>
                    ) : null}
                  </TableItem>
                </tr>
              ))}
        </Table>
        {!isLoading && data.length < 1 ? (
          <div className="text-center my-5 text-gray-500">
            <h1>Empty</h1>
          </div>
        ) : null}
      </div>
      {/* Delete Emirate Modal */}
      <Modal show={deleteShow}>
        <AlertTriangle size={30} className="text-red-500 mr-5 fixed" />
        <div className="ml-10">
          <h3 className="mb-2 font-semibold">Delete Emirate</h3>
          <hr />
          <p className="mt-2">
            Are you sure you want to delete the emirate? All of emirate's data
            will be permanently removed.
            <br />
            This action cannot be undone.
          </p>
        </div>
        <div className="flex flex-row gap-3 justify-end mt-5">
          <button
            className="btn"
            onClick={() => {
              setError(null);
              setDeleteShow(false);
            }}
            disabled={isDeleting}
          >
            Cancel
          </button>
          <button
            className="btn danger"
            onClick={handleDelete}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <Loader className="mx-auto animate-spin" />
            ) : (
              'Delete'
            )}
          </button>
        </div>
        {error ? (
          <div className="text-red-500 p-3 font-semibold border rounded-md bg-red-50">
            {error}
          </div>
        ) : null}
      </Modal>
      {/* Update Emirate Modal */}
      <Modal show={updateShow}>
        <div className="flex">
          <h1 className="font-semibold mb-3">Update Emirate</h1>
          <button
            className="ml-auto focus:outline-none"
            onClick={() => {
              setUpdateShow(false);
              setError(null);
              reset();
            }}
          >
            <X size={30} />
          </button>
        </div>
        <hr />

        <form
          className="flex flex-col gap-5 mt-5"
          onSubmit={handleSubmit(handleUpdate)}
        >
          <input
            type="text"
            className="input"
            placeholder="Name"
            required
            {...register('name')}
          />
          <input
            type="text"
            className="input"
            placeholder="Color"
            required
            disabled={isSubmitting}
            {...register('color')}
          />
          <div className="max-h-[180px]">
            <CodeMirror
              value={geometryStr}
              height="100%"
              maxHeight="200px"
              style={{ height: '100%' }}
              extensions={[jsonLang()]}
              onChange={(value) => {
                setGeometryStr(value);
                try {
                  setValue('geometry', JSON.parse(value));
                } catch (e) {}
              }}
            />
          </div>
          <button className="btn" disabled={isSubmitting}>
            {isSubmitting ? (
              <Loader className="animate-spin mx-auto" />
            ) : (
              'Save'
            )}
          </button>
          {error ? (
            <div className="text-red-500 p-3 font-semibold border rounded-md bg-red-50">
              {error}
            </div>
          ) : null}
        </form>
      </Modal>
    </>
  );
}
