import { useState } from 'react';

export enum MarkerType {
  black = 'black',
}

export interface IMarker {
  lat: number;
  lng: number;
  type?: MarkerType;
  id?: string;
  name: string;
  description: string;
  onClick?: () => void;
}

export default function Marker({ name, description, onClick }: IMarker) {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div className="relative w-0 h-0">
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: 350,
          height: 100,
          zIndex: 100,
        }}
        onClick={() => setShowInfo(false)}
        className={`bg-white rounded-lg text-black gap-y-2 p-1 flex-col justify-between items-center ${
          showInfo ? 'flex' : 'hidden'
        }`}
      >
        <div className="text-sm w-full justify-center border-b border-indigo-500 font-bold flex text-nowrap">
          {name}
        </div>
        <div className="text-xs font-normal overflow-y-auto">{description}</div>
      </div>
      <button
        className="z-1 w-0 h-0 relative"
        onClick={() => (onClick ? onClick() : setShowInfo(!showInfo))}
      >
        <img
          className="absolute bottom-0"
          src="/pin.png"
          alt={name || 'Pin'}
          width={40}
          height={60}
        />
      </button>
    </div>
  );
}
