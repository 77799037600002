import axios from 'axios';
import { openDB } from 'idb';

import authService from './AuthService';
const generateCacheKey = (config: any) => {
  return `/${config.method}${config.url}`;
};

// Open IndexedDB database
const dbName = 'my-cache-db';
const storeName = 'cache-store';

const openDatabase = async () => {
  return await openDB(dbName, 1, {
    upgrade(db) {
      db.createObjectStore(storeName);
    },
  });
};

const axiosInstance = axios.create({
  withCredentials: true,
});

axiosInstance.interceptors.request.use(async function (config) {
  if (config.method === 'get') {
    const key = generateCacheKey(config);
    const db = await openDatabase();
    const cachedData = await db.get(storeName, key);
    if (cachedData) {
      const res = JSON.parse(cachedData);
      config.data = res;
      (config as any).cached = true;
      // If response is already cached, return it
    }
  }
  return config;
});

axiosInstance.interceptors.response.use(
  async function (response) {
    if (response.config?.method === 'get' && !(response.config as any).cached) {
      const key = generateCacheKey(response.config);
      const db = await openDatabase();
      await db.put(storeName, JSON.stringify(response.data), key);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/* Auto refreshes the token if expired */
axiosInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const { token } = await authService.refresh();
        error.response.config.headers.Authorization = `Bearer ${token}`;
        return axiosInstance(error.response.config);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
