import { BookOpen, Home, List, LogOut, Map, Users } from 'react-feather';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import authService from '../../services/AuthService';
import SidebarItem from './SidebarItem';

interface SidebarProps {
  className: string;
}

export default function Sidebar({ className }: SidebarProps) {
  const history = useHistory();

  const { authenticatedUser, setAuthenticatedUser }: any = useAuth();

  const handleLogout = async () => {
    await authService.logout();
    setAuthenticatedUser(null as any);
    history.push('/login');
  };

  return (
    <div className={'sidebar ' + className}>
      <Link to="/" className="no-underline text-black">
        <h1 className="font-semibold text-center">Dubai Realty</h1>
      </Link>
      <nav className="mt-5 flex flex-col gap-3 flex-grow">
        {authenticatedUser.role === 'admin' && (
          <SidebarItem to="/">
            <Home /> Dashboard
          </SidebarItem>
        )}
        {authenticatedUser.role === 'admin' && (
          <SidebarItem to="/emirates">
            <BookOpen /> Emirates
          </SidebarItem>
        )}
        <SidebarItem to="/map">
          <Map /> Map
        </SidebarItem>
        {authenticatedUser.role === 'admin' && (
          <SidebarItem to="/companies">
            <List /> Companies
          </SidebarItem>
        )}
        {authenticatedUser.role === 'admin' && (
          <SidebarItem to="/users">
            <Users /> Users
          </SidebarItem>
        )}
      </nav>
      <button
        className="text-red-500 rounded-md p-3 transition-colors flex gap-3 justify-center items-center font-semibold focus:outline-none"
        onClick={handleLogout}
      >
        <LogOut /> Logout
      </button>
    </div>
  );
}
