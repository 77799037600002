import Project from '../../models/project/Project';
import Accordion from './accordion';

export default function MapSideBarUnits({ project }: { project: Project }) {
  return (
    <div className="w-full h-auto text-sm">
      <Accordion items={project.unitLayouts} />
    </div>
  );
}
