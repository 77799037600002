import cx from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import {
  CheckSquare,
  ChevronDown,
  ChevronRight,
  Square,
  X,
} from 'react-feather';
import ReactModal from 'react-modal';

export default function Filters({
  setIsOpen,
  isOpen,
  emirates,
  filter,
}: {
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  emirates?: any[];
  filter: (arg: number[]) => void;
}) {
  const [expanded, setExpanded] = useState<any>(null);
  const [checked, setChecked] = useState<any>({});
  useEffect(() => {
    ReactModal.setAppElement('#root');
  }, []);
  const selectAll = useCallback(
    (event, emirate) => {
      event.preventDefault();
      event.stopPropagation();
      const checks = checked[emirate.id] || {};
      if (Object.keys(checks).length == emirate.districts.length) {
        const items = { ...checked };
        delete items[emirate.id];
        setChecked(items);
      } else {
        setChecked({
          ...checked,
          [emirate.id]: emirate.districts.reduce((acc: any, item: any) => {
            acc[item.id] = true;
            return acc;
          }, {}),
        });
      }
    },
    [checked],
  );

  const submit = useCallback(() => {
    const ids = Object.values(checked)
      .reduce((acc: string[], em: any) => [...acc, ...Object.keys(em)], [])
      .map(Number);
    filter(ids);
  }, [checked]);

  const disabled = Object.keys(checked).length == 0;

  return (
    <ReactModal
      overlayClassName="absolute z-10"
      shouldCloseOnOverlayClick
      ariaHideApp={false}
      style={{
        overlay: {
          width: '100vw',
          height: '100vh',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: '#00000088',
        },
        content: {
          inset: 20,
          padding: 0,
        },
      }}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
    >
      <div className="flex flex-col justify-between h-full px-4 py-2 modal-body">
        <div className="py-2">
          <div className="w-full flex items-center justify-between">
            <h2 className="font-bold">Filter By Area</h2>
            <button onClick={() => setIsOpen(false)}>
              <X size={32} />
            </button>
          </div>
          <hr />
        </div>
        <div className="overflow-y-auto">
          <div className="flex flex-col justrify-start h-full">
            {emirates?.map(
              (emirate: any) =>
                emirate.districts.length > 0 && (
                  <div
                    key={emirate.id}
                    className="w-full flex flex-col items-center"
                  >
                    <button
                      onClick={() => {
                        const enabled = emirate.id === expanded;
                        setExpanded(enabled ? null : emirate.id);
                      }}
                      className="w-full flex items-center"
                    >
                      {expanded === emirate.id ? (
                        <ChevronDown />
                      ) : (
                        <ChevronRight />
                      )}
                      {Object.keys(checked[emirate.id] || {}).length > 0 ? (
                        <CheckSquare
                          className="mx-2"
                          onClick={(event) => selectAll(event, emirate)}
                        />
                      ) : (
                        <Square
                          className="mx-2"
                          onClick={(event) => selectAll(event, emirate)}
                        />
                      )}
                      <span>{emirate.name}</span>
                    </button>
                    {expanded === emirate.id && (
                      <div className="w-full flex flex-col gap-y-2 pl-10">
                        {emirate.districts.map((district: any) => (
                          <button
                            key={`${emirate.id}-${district.id}`}
                            className="w-full flex items-center"
                            onClick={() => {
                              const checks = checked[emirate.id] || {};
                              if (checks[district.id]) {
                                delete checks[district.id];
                              } else {
                                checks[district.id] = true;
                              }
                              setChecked({
                                ...checked,
                                [emirate.id]: checks,
                              });
                            }}
                          >
                            {checked?.[emirate.id]?.[district.id] ? (
                              <CheckSquare />
                            ) : (
                              <Square />
                            )}
                            <span className="ml-2">{district.name}</span>
                          </button>
                        ))}
                      </div>
                    )}
                    <hr />
                  </div>
                ),
            )}
          </div>
        </div>
        <div className="py-2">
          <hr />
          <div className="flex justify-end items-center gap-x-2 mt-2">
            <button
              disabled={disabled}
              className={cx(
                'h-full cursor-pointer py-2 px-8 rounded-lg text-black font-bold bg-gray-200',
                disabled && 'text-gray-400 cursor-default',
              )}
              onClick={() => {
                setChecked({});
                filter([]);
              }}
            >
              Reset
            </button>
            <button
              className="h-full cursor-pointer py-2 px-8 rounded-lg text-black font-bold bg-green-500"
              onClick={() => submit()}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
