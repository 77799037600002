export default function MapSideBarAmenities({ project }: any) {
  return (
    <div className="w-full h-auto text-sm bg-gray-200">
      {project.amenities.map((amenity: any) => (
        <p
          className="w-full font-bold text-md border-b border-gray-300 p-2"
          key={`amenity-${amenity.id}`}
        >
          {amenity.name}
        </p>
      ))}
    </div>
  );
}
