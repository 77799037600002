import Company from '../models/company/Company';
import CompanyQuery from '../models/company/CompanyQuery';
import CreateCompanyRequest from '../models/company/CreateCompanyRequest';
import UpdateCompanyRequest from '../models/company/UpdateCompanyRequest';
import apiService from './ApiService';

class CompanyService {
  async save(createCompanyRequest: CreateCompanyRequest): Promise<void> {
    await apiService.post('/api/emirates', createCompanyRequest);
  }

  async findAll(companyQuery: CompanyQuery): Promise<Company[]> {
    const { data }: any = await apiService.get<Company[]>('/api/company', {
      params: companyQuery,
    });
    return data?.companies || [];
  }

  async findOne(id: string): Promise<Company> {
    return (await apiService.get<Company>(`/api/company/${id}`)).data;
  }

  async update(
    id: string,
    updateCompanyRequest: UpdateCompanyRequest,
  ): Promise<void> {
    await apiService.patch(`/api/company/${id}`, updateCompanyRequest);
  }

  async delete(id: string): Promise<void> {
    await apiService.delete(`/api/company/${id}`);
  }
}

const companyService = new CompanyService();
export default companyService;
