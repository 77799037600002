import CreateProjectRequest from '../models/project/CreateProjectRequest';
import Project from '../models/project/Project';
import ProjectQuery from '../models/project/ProjectQuery';
import UpdateProjectRequest from '../models/project/UpdateProjectRequest';
import apiService from './ApiService';

class ProjectService {
  async save(createProjectRequest: CreateProjectRequest): Promise<void> {
    await apiService.post('/api/projects', createProjectRequest);
  }

  async findAll(projectQuery: ProjectQuery): Promise<Project[]> {
    const { data }: any = await apiService.get<Project[]>('/api/projects', {
      params: projectQuery,
    });
    return data?.projects || [];
  }

  async findOne(id: string): Promise<Project> {
    return (await apiService.get<Project>(`/api/projects/${id}`)).data;
  }

  async update(
    id: string,
    updateProjectRequest: UpdateProjectRequest,
  ): Promise<void> {
    await apiService.patch(`/api/projects/${id}`, updateProjectRequest);
  }

  async delete(id: string): Promise<void> {
    await apiService.delete(`/api/projects/${id}`);
  }
}

const projectService = new ProjectService();
export default projectService;
