import { ChevronDown, ChevronRight } from 'react-feather';

export default function AccordionItem({
  title,
  subTitle,
  content,
  isOpen,
  onToggle,
}: any) {
  return (
    <div className="border-b border-gray-300">
      <div
        className="flex justify-between items-center bg-gray-200 px-4 py-2 cursor-pointer"
        onClick={onToggle}
      >
        <div className="flex flex-col justify-center">
          <div className="text-black text-lg">{title}</div>
          {subTitle && <div className="text-gray-500">{subTitle}</div>}
        </div>
        <div className="transform transition-transform">
          {isOpen ? <ChevronRight /> : <ChevronDown />}
        </div>
      </div>
      {isOpen && <div className="px-4 py-2">{content}</div>}
    </div>
  );
}
