import { useContext } from 'react';
import { Redirect, Route } from 'react-router';

import { AuthenticationContext } from './context/AuthenticationContext';

export { Route } from 'react-router';

export function HomeRoute({ components, ...rest }: any) {
  const { authenticatedUser }: any = useContext(AuthenticationContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authenticatedUser) {
          return <Redirect to="/login" />;
        }
        const Component = components[authenticatedUser.role];
        return <Component {...props} />;
      }}
    />
  );
}
