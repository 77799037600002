import { formatPrice, formatQuarter } from '../../utils';

export default function MapSideBarInformation({ project }: any) {
  return (
    <div className="w-full h-auto text-sm">
      <div className="border-b border-gray-500 h-10 flex items-center justify-between px-2 bg-gray-200">
        <span className="text-gray-600">Developer</span>
        <span>{project.developer.name}</span>
      </div>
      <div className="border-b border-gray-500 h-10 flex items-center justify-between px-2 bg-gray-200">
        <span className="text-gray-600">District</span>
        <span>{project.district.name}</span>
      </div>
      <div className="border-b border-gray-500 h-10 flex items-center justify-between px-2 bg-gray-200">
        <span className="text-gray-600">Price</span>
        <div>
          <span>{formatPrice(project.price.min)}</span>
          <span> - </span>
          <span>{formatPrice(project.price.max)}</span>
        </div>
      </div>
      <div className="border-b border-gray-500 h-10 flex items-center justify-between px-2 bg-gray-200">
        <span className="text-gray-600">Size</span>
        <div>
          <span>{project.square.min / 100} m²</span>
          <span> - </span>
          <span>{project.square.max / 100} m²</span>
        </div>
      </div>
      <div className="border-b border-gray-500 h-10 flex items-center justify-between px-2 bg-gray-200">
        <span className="text-gray-600">Handover</span>
        <span>{formatQuarter(project.handover)}</span>
      </div>
      <div className="border-b border-gray-500 h-10 flex items-center justify-between px-2 bg-gray-200">
        <span className="text-gray-600">Unit type</span>
        <span>{project.unitTypes[0].name}</span>
      </div>
      <div className="border-b border-gray-500 h-10 flex items-center justify-between px-2 bg-gray-200">
        <span className="text-gray-600">Service charge</span>
        <span>{project.serviceCharge} AED/m²</span>
      </div>
      <div className="border-b border-gray-500 h-10 flex items-center justify-between px-2 bg-gray-200">
        <span className="text-gray-600">Managers name</span>
        <span>{project.developer.contactName}</span>
      </div>
      <div className="border-b border-gray-500 h-10 flex items-center justify-between px-2 bg-gray-200">
        <span className="text-gray-600">Managers phone</span>
        <span>{project.developer.contactPhone}</span>
      </div>
    </div>
  );
}
