import { useState } from 'react';

import AccordionItem from './accordion-item';

const Accordion = ({ items }: any) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className="border border-gray-300 rounded mx-2">
      {items.map((item: any, index: number) => (
        <AccordionItem
          key={`accrodion-item-${item.id}`}
          title={item.title}
          subTitle={item.subTitle}
          content={item.content}
          isOpen={openIndex === index}
          onToggle={() => handleToggle(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
