import classNames from 'classnames';
import { useMemo, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { useQuery } from 'react-query';
import ShowMoreText from 'react-show-more-text';

import { MENU_ITEMS } from '../../constants';
import projectService from '../../services/ProjectService';
import MapSideBarAmenities from './amenities';
import MapSideBarDocuments from './documents';
import MapSideBarInformation from './information';
import MapSideBarPaymentPlans from './payment-plans';
import MapSideBarUnits from './units';

export interface IMarker {
  id?: string;
  close: () => void;
}

interface IMenuItem {
  title: string;
  active?: boolean;
  id: number;
  onClick?: (id: number) => void;
}

function MenuItem({ title, active, id, onClick }: IMenuItem) {
  return (
    <li className="me-2" onClick={() => onClick?.(id)}>
      <a
        href="#"
        className={classNames(
          'w-32 no-underline inline-flex items-center justify-center py-4 px-2',
          'border-b-2 border-transparent rounded-t-lg',
          'text-gray-500 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300',
          active && 'active text-blue-600 border-b-2',
          active && 'border-blue-600 dark:text-blue-500 dark:border-blue-500',
          active && 'hover:text-blue-500 hover:border-blue-500',
        )}
        aria-current="page"
      >
        {title}
      </a>
    </li>
  );
}

function Menu({
  items,
  onActiveChange,
  activeId,
}: {
  items: IMenuItem[];
  onActiveChange: (id: number) => void;
  activeId: number;
}) {
  return (
    <div className="border-b border-gray-200 dark:border-gray-700 custom-box-shadow">
      <ul className="flex overflow-y-auto text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        {items.map((menu: any) => (
          <MenuItem
            key={`menu-item-${menu.id}`}
            active={activeId === menu.id}
            {...menu}
            onClick={onActiveChange}
          />
        ))}
      </ul>
    </div>
  );
}

const ACTIVE = {
  1: MapSideBarInformation,
  2: MapSideBarUnits,
  3: MapSideBarAmenities,
  4: MapSideBarPaymentPlans,
  5: MapSideBarDocuments,
};

export default function MapSideBar({ id, close }: IMarker) {
  const { data: project } = useQuery([`project-${id}`], () =>
    projectService.findOne(id as string),
  );
  const [activeTab, setActiveTab] = useState(1);
  const images = useMemo(() => {
    return project?.renderers?.map(({ url }) => ({
      original: url,
      originalClass: 'my-image-gallery-image',
      thumbnailClass: 'my-image-gallery-thumbnail',
    }));
  }, [project]);
  const Tab = ACTIVE[activeTab as keyof typeof ACTIVE];
  return (
    <div className="reltaive">
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: 400,
          height: '100vh',
          zIndex: 100,
        }}
        className="bg-white text-black gap-y-2 flex-col justify-between items-center flex"
      >
        <div className="w-full h-[100vh] relative">
          <button
            onClick={close}
            className="absolute top-2 right-2 z-10 bg-gray-200 rounded-2xl w-8 h-8 text-gray-500 text-lg flex items-center justify-center"
          >
            x
          </button>
          <ImageGallery
            infinite
            showFullscreenButton={false}
            slideDuration={500}
            lazyLoad
            showPlayButton={false}
            items={images || []}
            useTranslate3D
            showNav
            showBullets
            thumbnailPosition="bottom"
            additionalClass="w-full my-image-gallery"
          />
          <div className="my-menu-content overflow-y-auto">
            <h2 className="font-bold text-center">{project?.name}</h2>
            <ShowMoreText
              lines={3}
              more="Show"
              less="Hide"
              anchorClass="bg-white text-blue-500 border border-black rounded-lg text-black px-2 cursor-pointer"
              expanded={false}
              truncatedEndingComponent={'... '}
            >
              {project?.description}
            </ShowMoreText>
            <Menu
              items={MENU_ITEMS}
              activeId={activeTab}
              onActiveChange={setActiveTab}
            />
            {project && <Tab project={project} />}
          </div>
        </div>
      </div>
    </div>
  );
}
