export const formatPrice = (price: number, currency = 'AED') => {
  return (
    price
      .toLocaleString('en-GB', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .substring(-3) + ` ${currency}`
  );
};

export const formatQuarter = (input: string) => {
  const date = new Date(input);
  const month = date.getMonth();
  const year = date.getFullYear();

  const quarter = Math.floor(month / 3) + 1;

  return `Q${quarter}/${year}`;
};
