import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { HomeRoute } from './HomeRoute';
import useAuth from './hooks/useAuth';
import Companies from './pages/Companies';
import Dashboard from './pages/Dashboard';
import Emirates from './pages/Emirates';
import Login from './pages/Login';
import Map from './pages/Map';
import PublicMap from './pages/PublicMap';
import Users from './pages/Users';
import { AuthRoute, PrivateRoute } from './Route';
import authService from './services/AuthService';

export default function App() {
  const { authenticatedUser, setAuthenticatedUser }: any = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);

  const authenticate = async () => {
    try {
      const authResponse = await authService.refresh();
      setAuthenticatedUser(authResponse.user);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (!authenticatedUser) {
      authenticate();
    } else {
      setIsLoaded(true);
    }
  }, []);
  return isLoaded ? (
    <Router>
      <Switch>
        <HomeRoute
          exact
          path="/"
          components={{
            admin: Dashboard,
            owner: PublicMap,
            broker: PublicMap,
          }}
        />
        <PrivateRoute exact path="/users" component={Users} roles={['admin']} />
        <PrivateRoute
          exact
          path="/emirates"
          component={Emirates}
          roles={['admin']}
        />
        <PrivateRoute
          exact
          path="/companies"
          component={Companies}
          roles={['admin']}
        />
        <PrivateRoute
          exact
          path="/map"
          component={Map}
          roles={['admin', 'owner', 'broker']}
        />

        <AuthRoute exact path="/login" component={Login} />
      </Switch>
    </Router>
  ) : null;
}
