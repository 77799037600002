import CreateEmirateRequest from '../models/emirate/CreateEmirateRequest';
import Emirate from '../models/emirate/Emirate';
import EmirateQuery from '../models/emirate/EmirateQuery';
import UpdateEmirateRequest from '../models/emirate/UpdateEmirateRequest';
import apiService from './ApiService';

class EmirateService {
  async save(createEmirateRequest: CreateEmirateRequest): Promise<void> {
    await apiService.post('/emirates', createEmirateRequest);
  }

  async findAll(emirateQuery: EmirateQuery): Promise<Emirate[]> {
    const { data }: any = await apiService.get<Emirate[]>('/api/emirates', {
      params: emirateQuery,
    });
    return data?.emirates || [];
  }

  async findOne(id: string): Promise<Emirate> {
    return (await apiService.get<Emirate>(`/api/emirates/${id}`)).data;
  }

  async update(
    id: string,
    updateEmirateRequest: UpdateEmirateRequest,
  ): Promise<void> {
    await apiService.patch(`/api/emirates/${id}`, updateEmirateRequest);
  }

  async delete(id: string): Promise<void> {
    await apiService.delete(`/api/emirates/${id}`);
  }
}

const emirateService = new EmirateService();
export default emirateService;
