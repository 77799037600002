import { useState } from 'react';
import { Menu, X } from 'react-feather';

import Sidebar from './Sidebar';

export default function Layout({ children, isMap = false }: any) {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <>
      <Sidebar className={showSidebar ? 'show' : ''} />
      <div
        className={`lg:ml-56 mx-auto px-5 ${
          isMap ? 'pt-1 py-0' : 'pt-10 py-5'
        }`}
      >
        {children}
      </div>
      <button
        className={`fixed bottom-5 border shadow-md bg-white p-3 rounded-full transition-all focus:outline-none lg:hidden ${
          showSidebar ? 'right-5' : 'left-5'
        }`}
        onClick={() => setShowSidebar(!showSidebar)}
      >
        {showSidebar ? <X size={16} /> : <Menu size={16} />}
      </button>
    </>
  );
}
